<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="w-100 d-flex align-items-center justify-content-between">
        <h1>ویرایش سکشن زیر اسلایدر</h1>
        <b-button
          size="sm"
          type="button"
          variant="primary"
          v-b-modal.modal-create
        >
          افزودن آیتم جدید
        </b-button>
      </div>
      <b-row align-content="center" v-if="settingDetails">
        <b-col cols="12">
          <div
            class="w-100 d-flex align-items-center justify-content-center"
            v-if="
              !settingDetails.externalSliders ||
              settingDetails.externalSliders.length == 0
            "
          >
            <p class="text-center">هیچ آیتمی جهت نمایش وجود ندارد</p>
          </div>
          <div v-else>
            <b-row>
              <b-col
                cols="12"
                md="3"
                class="my-1"
                v-for="slider in settingDetails.externalSliders"
                :key="slider.priority"
              >
                <b-img
                  class="cursor-pointer"
                  @click="renderImageModal(slider)"
                  :src="handleImageUrl(slider)"
                  alt=""
                  style="object-fit: object-contain"
                  height="auto"
                  width="200"
                />
                <p class="mt-2">{{ slider.title }}</p>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button variant="success" v-b-modal.modal-create>
          افزودن آیتم جدید
        </b-button>
      </div>
    </b-card>
    <b-modal
      id="modal-create"
      centered
      size="lg"
      ok-variant="success"
      title="افزودن آیتم"
      ok-title="افزودن"
      cancelTitle="انصراف"
      @ok="pushNewSlider"
    >
      <b-container fluid>
        <b-form-row>
          <b-col cols="12">
            <!-- <b-form @submit.prevent>
                <b-form-group label="لینک صفحه" label-for="link">
                  <b-form-input v-model="newAwardDetails.link"></b-form-input>
                </b-form-group>
              </b-form> -->
            <b-form @submit.prevent>
              <b-form-group label="عکس آیتم" label-for="imagePicker">
                <b-form-file
                  id="imagePicker"
                  ref="refInputEl"
                  accept=".jpg, .png, .gif"
                  placeholder="انتخاب عکس"
                  @input="makeBase64Pic"
                />
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="عنوان آیتم" label-for="title">
                <b-form-input
                  id="title"
                  placeholder="کارگزار تمام خدمات بازار سرمایه"
                  v-model="newSliderDetails.title"
                ></b-form-input>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12">
            <b-form @submit.prevent>
              <b-form-group label="توضیحات آیتم" label-for="description">
                <b-form-textarea
                  id="description"
                  placeholder="شرکت کارگزاری ستاره جنوب، کارگزار رسمی بورس و ..."
                  v-model="newSliderDetails.description"
                ></b-form-textarea>
              </b-form-group>
            </b-form>
          </b-col>
          <b-col cols="12">
            <small>دکمه آیتم</small>
            <hr />
            <b-row>
              <b-col cols="6">
                <b-form @submit.prevent>
                  <b-form-group label="متن دکمه" label-for="linkName">
                    <b-form-input
                      v-model="newSliderDetails.linkName"
                      id="linkName"
                      placeholder="ادامه مطلب"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
              </b-col>
              <b-col cols="6">
                <b-form @submit.prevent>
                  <b-form-group label="لینک دکمه" label-for="link">
                    <b-form-input
                      v-model="newSliderDetails.link"
                      id="link"
                      placeholder="https://sjbourse.ir/صندوق-تمشک"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <div
              class="w-100 d-flex align-items-center justify-content-center my-2"
            >
              <b-img
                v-if="imagePreview"
                :src="imagePreview"
                height="auto"
                width="400"
                class="rounded"
              />
            </div>
          </b-col>
        </b-form-row>
      </b-container>
    </b-modal>
    <slider-image-modal
      :sliderDetails="tempImage"
      @refetch="getSetting"
      @loading="loading"
      @loaded="loaded"
    ></slider-image-modal>
  </b-overlay>
</template>
<script>
export default {
  title: "تنظیمات آیتم ها",
  mounted() {
    this.getSetting();
  },
  data() {
    return {
      isLoading: false,
      settingDetails: null,
      newSliderDetails: {
        indexSliderId: 0,
        title: null,
        link: null,
        linkName: null,
        description: null,
        priority: 0,
        type: 2, // 2 is for external slider
        imageUrl: null,
        createDate: new Date(Date.now()).toISOString(),
      },
      fileDetails: {
        base64: null,
        objectId: 0,
        type: 10, // 10 is for library
        priority: null,
      },
      libraryBase64: null,
      imagePreview: null,
      tempImage: null,
    };
  },
  methods: {
    loading() {
      this.isLoading = true;
    },
    loaded() {
      this.isLoading = false;
    },
    async createNewSlider() {
      try {
        let _this = this;
        let createNewIndexSlider = new CreateNewIndexSlider(_this);
        createNewIndexSlider.setRequestParamDataObj(_this.newSliderDetails);
        await createNewIndexSlider.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `فایل آپلود شد.`,
              },
            });
            _this.getSetting();
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getSetting() {
      try {
        this.tempImage = null;
        this.newSliderDetails = {
          indexSliderId: 0,
          title: null,
          link: null,
          linkName: null,
          description: null,
          priority: 0,
          type: 2, // 2 is for external slider
          imageUrl: null,
          createDate: new Date(Date.now()).toISOString(),
        };
        this.isLoading = true;
        let _this = this;
        let getASingleSetting = new GetASingleSetting(_this);
        getASingleSetting.setRequestParam({ id: 1 });
        await getASingleSetting.fetch((response) => {
          if (response.isSuccess) {
            _this.settingDetails = response.data;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async updateSetting() {
      try {
        this.isLoading = true;
        let _this = this;
        let updateASetting = new UpdateASetting(_this);
        updateASetting.setRequestParamDataObj(_this.settingDetails);
        await updateASetting.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: `عملیات موفق`,
                icon: "CheckIcon",
                variant: "success",
                text: `آیتم بروز شد.`,
              },
            });
            _this.getSetting();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendCreateFileRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let createFile = new CreateFile(_this);
        createFile.setRequestParamDataObj(_this.fileDetails);
        await createFile.fetch((response) => {
          if (response.isSuccess) {
            _this.newSliderDetails.imageUrl = response.data.imageName;
            _this.createNewSlider();
            _this.imagePreview = null;
            _this.fileDetails = {
              base64: null,
              objectId: 0,
              type: 10, // 10 is for library
              priority: null,
            };
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    pushNewSlider() {
      this.newSliderDetails.priority =
        this.settingDetails.externalSliders?.length > 0
          ? this.settingDetails.externalSliders[
              this.settingDetails.externalSliders?.length - 1
            ].priority + 1
          : 1;
      this.sendCreateFileRequest();
    },
    renderImageModal(slider) {
      this.tempImage = JSON.parse(JSON.stringify(slider));
      this.$bvModal.show("details-modal");
    },
    handleImageUrl(slider) {
      if (slider.imageUrl) {
        return `${Helper.baseUrl}/Media/Gallery/Library/${slider.imageUrl}`;
      }
    },
    async makeBase64Pic() {
      const _this = this;
      let file = _this.$refs.refInputEl.files[0];
      _this.imagePreview = URL.createObjectURL(file);
      const result = await _this.readAsDataURL(file);
      _this.fileDetails.base64 = result;
    },
    async readAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result.split(",")[1]);
        };
        fr.readAsDataURL(file);
      });
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormCheckbox,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BBadge,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    BModal,
    SliderImageModal,
  },
};
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { GetASingleSetting, UpdateASetting } from "@/libs/Api/Setting";
import { CreateFile } from "@/libs/Api/Media";
import { CreateNewIndexSlider } from "@/libs/Api/IndexSlider";
import Helper from "@/libs/Helper";
import SliderImageModal from "@/views/components/utils/SliderImageModal.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BMediaBody,
  BForm,
  BFormCheckbox,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BBadge,
  BFormTextarea,
  BOverlay,
  BFormSelect,
  BModal,
} from "bootstrap-vue";
</script>
